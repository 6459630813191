import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'Routes/routes';
import LocalStorageHelper from 'utils/LocalStorageHelper';

// To Navigate user to login screen if not logged in 
export default function AuthGuard({ children }) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!LocalStorageHelper.isLoggedIn()) {
            navigate(routes.Login.Login);
            return;
        }

        if (!LocalStorageHelper.isEntitySelected()) {
            navigate(routes.Entity.Selection);
            return;
        }
    }, [navigate]);

    return children;
}