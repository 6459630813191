class LocalStorageHelper {
    static ADMIN_KEY = 'admin';
    static ACCESS_TOKEN_KEY = 'accessToken';

    static setAccessToken(token) {
        localStorage.setItem(this.ACCESS_TOKEN_KEY, token);
    }

    static getAccessToken() {
        return localStorage.getItem(this.ACCESS_TOKEN_KEY);
    }

    static setAdmin(admin) {
        localStorage.setItem(this.ADMIN_KEY, JSON.stringify(admin));
    }

    static getAdmin() {
        const adminStr = localStorage.getItem(this.ADMIN_KEY);
        try {
            return adminStr ? JSON.parse(adminStr) : null;
        } catch (e) {
            return null;
        }
    }

    static isLoggedIn() {
        return !!(this.getAccessToken() && this.getAdmin());
    }

    static isEntitySelected() {
        const admin = this.getAdmin();
        return admin && admin.EntityId;
    }

    static getEntityId() {
        const admin = this.getAdmin();
        return admin ? admin.EntityId : null;
    }

    static clearAuth() {
        localStorage.removeItem(this.ACCESS_TOKEN_KEY);
        localStorage.removeItem(this.ADMIN_KEY);
    }

    static removeEntityId() {
        const admin = this.getAdmin();
        if (admin) {
            admin.EntityId = null;
            this.setAdmin(admin);
        }
    }
}

export default LocalStorageHelper;