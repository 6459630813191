import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, message } from 'antd';
import 'Components/GlobalStyle.css';
import apis from 'utils/apis';
import { routes } from 'Routes/routes'
import LocalStorageHelper from 'utils/LocalStorageHelper';

export default function LoginPage(props) {
    let navigate = useNavigate();

    useEffect(() => {
        if (LocalStorageHelper.isLoggedIn()) {
            if (LocalStorageHelper.isEntitySelected()) {
                navigate(routes.Dashboard.View);
            } else {
                navigate(routes.Entity.Selection);
            }
        }
    }, [navigate]);

    async function loginMDM(requestBody) {
        try {
            var response = await apis.loginUser(requestBody);
            if (response) {
                navigate(routes.Entity.Selection);
            }
        }
        catch (e) {
            const errorData = JSON.parse(e.message);
            message.error(errorData.message || 'Login failed!');
        }
    }

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
            <img 
                src="/pramukhmdm_logo.png" 
                alt="PramukhMDM Logo" 
                style={{ width: '150px', marginBottom: '20px' }}
            />
            <h1 style={{ marginBottom: '30px' }}>PramukhMDM</h1>
            <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                style={{ width: '100%', maxWidth: '500px' }}
                initialValues={{ remember: true }}
                onFinish={loginMDM}
                autoComplete="off"
            >
                <Form.Item
                    label="Email"
                    name="email"
                    validateTrigger={['onBlur']}
                    rules={[
                        { required: true, message: 'Please input your email address!' },
                        { pattern: emailPattern, message: 'Please enter a valid email address!' }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    validateTrigger={['onBlur']}
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="primary" htmlType="submit" style={{ width: 'auto', minWidth: '120px' }}>
                            Login
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}
