import React from "react";
import { Breadcrumb } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import Navigation from "Components/Navigation";

export default function DashboardPage() {
    const breadcrumb = ["Dashboard"]
    const content = (<div>
        <Breadcrumb style={{ margin: '6px 0' }}>
            {breadcrumb.map((item, i) => { return <Breadcrumb.Item key={i}>{item}</Breadcrumb.Item> })}
        </Breadcrumb>
        <div className="site-layout-content">Coming soon...</div>
    </div>)

    return (
        <div>
            <Navigation
                selectedKeys={['0']}
                content={content}
            />
        </div>
    );
}
