import LocalStorageHelper from './LocalStorageHelper';

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {
    const status = response.status;
    const responseBody = await response.json();

    if (status === 204 || status === 205) {
        return null;
    }

    if (status >= 200 && status < 300) {
        return responseBody;
    }

    if (status === 401) {
        // sending to login if unauthorised
        window.location = '/';
        LocalStorageHelper.clearAuth();
    }

    const error = new Error(JSON.stringify(responseBody));
    throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default async function request(url, options) {
    const accessToken = LocalStorageHelper.getAccessToken();
    const ApiOptions = {
        ...options,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${accessToken}`,
            ...options.headers
        }
    }
    const response = await fetch(url, ApiOptions);
    return await checkStatus(response);
}
