import React from 'react';
import { Layout, Menu, Avatar, Dropdown, Modal, message } from 'antd';
import { useNavigate } from "react-router-dom";
import { routes } from 'Routes/routes'
import apis from 'utils/apis';
import { UserOutlined, LogoutOutlined, BankOutlined } from '@ant-design/icons';
import LocalStorageHelper from 'utils/LocalStorageHelper';

const { Header, Content } = Layout;
const menu = [
  { name: "Home", link: routes.Dashboard.View },
  { name: "Policies", link: routes.Policy.List },
  { name: "Users", link: routes.User.List },
  { name: "Devices", link: routes.Device.List },
  { name: "Groups", link: routes.Groups.List },
  { name: "Applications", link: routes.Application.List }
]

function Navigation(props) {
  let navigate = useNavigate();

  const handleLogout = () => {
    Modal.confirm({
      title: 'Confirm Logout',
      content: 'Are you sure you want to logout?',
      onOk: async () => {
        try {
          const response = await apis.logout();
          if (response) {
            navigate(routes.Login.Login);
          } else {
            throw new Error();
          }
        } catch (error) {
          message.error('Failed to logout! Please try again.');
        }
      }
    });
  };

  const userMenu = {
    items: [
      {
        key: 'switch-org',
        icon: <BankOutlined />,
        label: 'Switch Organization',
        onClick: () => {
          LocalStorageHelper.removeEntityId();
          navigate(routes.Entity.Selection);
        }
      },
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: 'Logout',
        onClick: handleLogout
      }
    ]
  };

  return (
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%', marginBottom: '100px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/pramukhmdm_logo.png" alt="PramukhMDM Logo" style={{ height: '32px', marginRight: '24px' }} />
          <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} selectedKeys={props.selectedKeys} onClick={(val) => { navigate(menu[val.key].link) }} style={{ flex: 1 }}>
              {menu.map((item, i) => {
                return <Menu.Item key={i}>{item.name}</Menu.Item>
              })}
            </Menu>
            <Dropdown menu={userMenu} placement="bottomRight">
              <Avatar icon={<UserOutlined />} style={{ marginLeft: 16, cursor: 'pointer' }} />
            </Dropdown>
          </div>
        </div>
      </Header>
      <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
        <div className="site-layout-background" style={{ minHeight: "90vh", minWidth: "100vh", marginTop: 20 }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 0px'
          }}>
            {props.addDataLeft}
            <div />
            {props.addData}
          </div>
          {props.content}
        </div>
      </Content>
    </Layout >
  );
}

export default Navigation;