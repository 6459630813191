import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { pages, routes } from 'Routes/routes'
import AuthGuard from 'Components/AuthGuard'

export default function BasicExample() {
  return (
    <Router>
      <Routes>
        {Object.keys(pages).map(
          (path, i) => {
            const DRoute = pages[path];
            return <Route path={path} element={
              path === routes.Login.Login ? 
                <DRoute /> : 
                <AuthGuard><DRoute /></AuthGuard>
            } key={i} />
          }
        )}
      </Routes>
    </Router >
  );
}

